import React from "react";
import "./RGBGuesser.css"

class PreviousScore extends React.PureComponent {
  render () {
    return (
      <h1>{this.props.score}</h1>
    )
  }
}

class RGBGuesser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      r: 0,
      g: 0,
      b: 0,
      user_r: 0,
      user_g: 0,
      user_b: 0,
      previous_score: 0,
      results_mode: false,
      previous_scores: []
    }
    this.UpdateUserColour = this.UpdateUserColour.bind(this);
    this.checkResults = this.checkResults.bind(this);
    this.submitButton = this.submitButton.bind(this);
  }

  submitButton() {
    if (this.state.results_mode) {
      console.log("Refresh button pressed")
      this.SetNewColours()
    }
    else {
      console.log("Submit button pressed")
      this.checkResults()
    }
  }

  async SetNewColours() {
    this.setState({user_r: 0, user_g: 0, user_b: 0})
    this.setState({r: await this.RandomInt(255)})
    this.setState({g: await this.RandomInt(255)})
    this.setState({b: await this.RandomInt(255)})
    this.setState({results_mode:false})
    this.setState({colour_string: this.state.r + "," + this.state.g + "," + this.state.b})
    console.log("Colour string set to " + this.state.colour_string)
  }

  checkResults() {
    this.setState({results_mode: true})
    let score = this.calculateScore()
    this.addToPreviousScores(score)
    console.log("Overall score ", score, "%")
    this.setState({previous_score: score})
  }

  addToPreviousScores(score) {
    if (this.state.previous_scores.length > 10) {
      this.state.previous_scores.pop()
    }
    this.state.previous_scores.splice(0, 0, score)
  }

  calculateScore() {
    console.log("User entered " + this.state.user_r + "," + this.state.user_g + "," + this.state.user_b + " Results were: " + this.state.r + "," + this.state.g + "," + this.state.b)
    let r_score = (this.state.r - this.state.user_r) ** 2
    let g_score = (this.state.g - this.state.user_g) ** 2
    let b_score = (this.state.b - this.state.user_b) ** 2
    let euclidean_distance = Math.sqrt(r_score + g_score + b_score)
    console.log("R Score: ", r_score)
    console.log("G Score: ", g_score)
    console.log("B Score: ", b_score)
    return Math.round(100 * (1 - (euclidean_distance/255)));
  }

  UpdateUserColour(event) {
    if (this.state.results_mode) {
      return
    }
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value)) {
      let value = parseInt(event.target.value)
      if (0 <= value <= 255) {
        this.setState({[event.target.name]: parseInt(event.target.value)});
      }
    }
    console.log(this.state.user_b)
  }

  componentDidMount() {
    this.SetNewColours()
  }

  RandomInt(max) {
    return Math.floor(Math.random() * max)
  }

  render(){
    console.log(this.state.r, this.state.g, this.state.b)
    return (
      <div className="rgb_guesser">
        <div className="rgb_main">
          <div className="main_box" style={{backgroundColor: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`}}>
            <h1 className="results_text" style={{color: 'white'}}>{this.state.results_mode ? this.state.colour_string: ''}</h1>
          </div>
          <div className="input_div">
            <label className="input_label" style={{color: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`}}>
              R:
              <input placeholder={this.state.user_r} name='user_r' className="input_box" onBlur={this.UpdateUserColour}/>
            </label>
            <label className="input_label" style={{color: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`}}>
              G:
              <input placeholder={this.state.user_g} name='user_g' className="input_box" onBlur={this.UpdateUserColour}/>
            </label>
            <label className="input_label" style={{color: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`}}>
              B:
              <input placeholder={this.state.user_b} name='user_b' className="input_box" onBlur={this.UpdateUserColour}/>
            </label>
          </div>
          <button className="submit_button" name="submit" onClick={this.submitButton}>{this.state.results_mode ? "Reload" : "Submit"}</button>
          <h1 className="previous_score">{this.state.previous_score}%</h1>
          <h3 className="credit">Idea credit goes to Cameron</h3>
        </div>
        <div className="side_bar">
          {this.state.previous_scores.map(score => <PreviousScore score={score} />)}
        </div>
      </div>
    );
  }
}

export default RGBGuesser;
