import React from "react";
import './MainPage.css';

class MainPage extends React.Component {
	render() {
		return (
			<div className="MainPage">
				<header className="MainHeader">
					<h1 className="MainTitle">euan's website</h1>
				</header>
			</div>
		);
	}
}


export default MainPage;
